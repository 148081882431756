import React, { useState, useEffect, useCallback, Suspense } from "react";
import { Box, Typography, ToggleButton, ToggleButtonGroup, Card } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { tokens } from "../../../../theme";
import {
  CashFlowProjectionData,
  PropertyDetailsData,
  KeyAssumptionsData,
  PIScenarioData,
  IOScenarioData,
  CashFlowYearData,
} from "../../../../types/CashFlow";
// import CashFlowScenario from "../cashFlowScenario";
import KeyAssumptions from "../keyAssumptions";
import { updateCashFlowProjection, updateKeyAssumptions } from "../../../../features/cashFlow";
import { calculateTaxWithDetails, vacantPerioudGrossRent } from "../../../../hooks/properties/properties";

const CashFlowScenario = React.lazy(() => import('../cashFlowScenario'));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "none",
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(16),
  padding: theme.spacing(1.5, 3),
  border: `2px solid ${theme.palette.primary.main}`,
  "&.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

interface CashFlowProjectionProps {
  propertyDetails: PropertyDetailsData | null;
  keyAssumptions?: KeyAssumptionsData | null;
  cashFlowProjection: CashFlowProjectionData | null;
  graphs?: any
  // updateCashFlowProjections: (projection: CashFlowProjectionData) => void;
}

const CashFlowProjection: React.FC<CashFlowProjectionProps> = ({
  propertyDetails,
  keyAssumptions,
  cashFlowProjection,
  graphs
  // updateCashFlowProjections,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selectedScenario, setSelectedScenario] = useState<"scenario1" | "scenario2">("scenario1");
  // const [selectedScenario, setSelectedScenario] = useState('scenario1');
  const dispatch = useDispatch();
  const cashFlowAnalysis = useSelector((state: RootState) => state.cashFlowAnalysis);
  console.log('cashFlowAnalysis000000', cashFlowAnalysis)
  const [isLoading, setIsLoading] = useState(false);
  const handleScenarioChange = (event: React.MouseEvent<HTMLElement>, newScenario: "scenario1" | "scenario2") => {
    setIsLoading(true)
    setSelectedScenario(newScenario);
    setTimeout(() => {

      setIsLoading(false); // Stop loading once data is fetched
    }, 1000);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const calculatePrincipalInterestRepayment = (
  //   (loanAmount: number, interestRate: number, loanTerm: number): number => {

  //     const annualInterestRate = interestRate / 100;
  //     // const monthlyInterestRate = interestRate / 100 / 12;
  //     // const numPayments = loanTerm * 12;
  //     const numPayments = loanTerm;
  //     const payment =
  //       (loanAmount * (annualInterestRate * Math.pow(1 + annualInterestRate, numPayments))) /
  //       (Math.pow(1 + annualInterestRate, numPayments) - 1);

  //     console.log('payment :>> ', payment);
  //     return payment;
  //   }
  //   // [loanAmount, interestRate, loanTerm]
  // );
  // useEffect(() => {

  //   setIsLoading(true);
  //   const timer = setTimeout(() => {
  //     console.log("Loadint start object");
  //     setIsLoading(false); 
  //   }, 500); 

  //   return () => clearTimeout(timer);
  // }, [selectedScenario]);
  useEffect(() => {
    if (!selectedScenario) return;
  }, [selectedScenario]);
  const calculatePrincipalInterestRepayment = useCallback((loanAmount: number, annualInterestRate: number, loanTermYears: number) => {

    // Convert annual interest rate to monthly rate
    const monthlyInterestRate = annualInterestRate / 12 / 100;

    // Calculate total number of monthly payments
    const totalPayments = loanTermYears * 12;

    // Calculate monthly repayment
    const monthlyRepayment = (loanAmount * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPayments)) /
      (Math.pow(1 + monthlyInterestRate, totalPayments) - 1);

    let schedule = [];
    let remainingBalance = loanAmount;
    let totalInterestPaid = 0;
    let yearlyData = [];

    // Calculate amortization schedule month by month
    for (let month = 1; month <= totalPayments; month++) {
      const interestPayment = remainingBalance * monthlyInterestRate;
      const principalPayment = monthlyRepayment - interestPayment;

      totalInterestPaid += interestPayment;
      remainingBalance -= principalPayment;

      // Store monthly data
      schedule.push({
        month,
        payment: monthlyRepayment,
        principal: principalPayment,
        interest: interestPayment,
        remainingBalance: Math.max(0, remainingBalance),
        totalInterestPaid
      });

      // If it's the last month of a year or the last payment, summarize the year
      if (month % 12 === 0 || month === totalPayments) {
        const yearNumber = Math.ceil(month / 12);
        const yearlyPayments = schedule.slice(-12);

        const yearSummary = {
          year: yearNumber,
          annualRepayment: yearlyPayments.reduce((sum, payment) => sum + payment.payment, 0),
          annualPrincipal: yearlyPayments.reduce((sum, payment) => sum + payment.principal, 0),
          annualInterest: yearlyPayments.reduce((sum, payment) => sum + payment.interest, 0),
          remainingBalance: Math.max(0, remainingBalance)
        };

        yearlyData.push(yearSummary);
      }
    }

    return {
      monthlyPayment: Math.round(monthlyRepayment * 100) / 100,
      totalInterestPaid: Math.round(totalInterestPaid * 100) / 100,
      yearlySchedule: yearlyData.map(year => ({
        year: year.year,
        annualRepayment: Math.round(year.annualRepayment * 100) / 100,
        annualPrincipal: Math.round(year.annualPrincipal * 100) / 100,
        annualInterest: Math.round(year.annualInterest * 100) / 100,
        remainingBalance: Math.round(year.remainingBalance * 100) / 100
      }))
    };
  }, []);

  // // Test with a $500,000 loan at 4.5% for 30 years
  // const result = calculateAmortizationSchedule(500000, 4.5, 30);

  // console.log(Monthly Payment: $${ result.monthlyPayment });
  // console.log(Total Interest Paid: $${ result.totalInterestPaid });
  // console.log('\nYearly Amortization Schedule (first 5 years):');
  // result.yearlySchedule.slice(0, 5).forEach(year => {
  //   console.log(\nYear ${ year.year }: );
  //   console.log(`  Annual Repayment: $${year.annualRepayment}`);
  //   console.log(`  Annual Principal: $${year.annualPrincipal}`);
  //   console.log(`  Annual Interest: $${year.annualInterest}`);
  //   console.log(`  Remaining Balance: $${year.remainingBalance}`);
  //   console.log(`  Sum Check: ${Math.abs((year.annualPrincipal + year.annualInterest) - year.annualRepayment) < 0.01 ? 'Valid' : 'Invalid'}`);
  // });

  // // Verify the loan is fully paid off
  // console.log(\nFinal year remaining balance: $${ result.yearlySchedule[result.yearlySchedule.length - 1].remainingBalance });

  // // Calculate total payments vs original loan
  // const totalPayments = result.yearlySchedule.reduce((sum, year) => sum + year.annualRepayment, 0);
  // console.log(\nTotal amount paid: $${ Math.round(totalPayments * 100) / 100 });
  // console.log(Original loan amount: $500,000);
  // console.log(Total interest paid: $${ result.totalInterestPaid });
  useEffect(() => {

  }, [cashFlowAnalysis.depreciation.depreciation, cashFlowAnalysis.propertyDetails?.interestRate])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const calculateInterestOnlyRepayment = (
    (loanAmount: number, interestRate: number): number => {

      // const monthlyInterestRate = interestRate / 100 / 12;
      // // const monthlyInterestRate = interestRate / 100 ;
      // // const interestPayment = loanAmount * monthlyInterestRate;
      // const interestPayment = loanAmount * monthlyInterestRate;
      const annualInterestRate = interestRate / 100;
      const interestPayment = loanAmount * annualInterestRate;
      console.log('interestPayment :>> ', interestPayment);
      return interestPayment;
    }
  );

  const generateCashFlowYearsData = useCallback(
    (
      propertyDetails: PropertyDetailsData,
      keyAssumptions: KeyAssumptionsData,
      principalInterestRepayment: number,
      interestOnlyRepayment: number
    ): CashFlowYearData[] => {
      const { estimatedWeeklyRent, annualHoldingCosts, propertyValue, loanAmount, interestRate, loanTerm } = propertyDetails;
      const { capitalGrowthRate, rentalGrowthRate, inflationRate, vacancyPeriod } = keyAssumptions;
      const PMFofWR = ((annualHoldingCosts.propertyManagementFee * estimatedWeeklyRent) / 100) * 52
      // const YearInterestRate = cashFlowProjection?.scenario2.cashFlowYears.map(year => year.InterestRate)

      console.log('PMFofWR', PMFofWR)
      const LFofWR = annualHoldingCosts.lettingFee * estimatedWeeklyRent;
      const cashFlowYears: CashFlowYearData[] = [];
      // const previousInterestRate = propertyDetails.interestRate !== interestRate ? interestRate : 0
      // 
      // console.log('previousInterestRate', previousInterestRate)
      let currentPropertyValue = propertyValue;
      let currentLoanAmount = loanAmount;
      let currentRent = estimatedWeeklyRent * vacantPerioudGrossRent(vacancyPeriod);


      let currentRentalExpenses =
        (PMFofWR + LFofWR +
          annualHoldingCosts.maintenanceCostPA +
          annualHoldingCosts.councilRates +
          annualHoldingCosts.waterRates +
          annualHoldingCosts.insurance +
          annualHoldingCosts.miscellaneous_costs)
      for (let year = 1; year <= 30; year++) {
        // const YearInterestRate = interestRate !== 0 ? interestRate : cashFlowProjection?.scenario2.cashFlowYears[year - 1].InterestRate;
        const YearInterestRate = cashFlowProjection?.scenario2.cashFlowYears[year - 1].InterestRate !== 0 ? cashFlowProjection?.scenario2.cashFlowYears[year - 1].InterestRate : interestRate !== 0 ? interestRate : 0

        const PrincipleinterestCharged = Math.round(((YearInterestRate || 0) / 100) * (selectedScenario === "scenario1" ? currentLoanAmount : loanAmount) * 100) / 100;

        // Property Value
        if (year > 1) {
          currentPropertyValue *= 1 + capitalGrowthRate / 100;
        }

        // Loan Amount
        if (year === 1) {

          currentLoanAmount = loanAmount;

        } else {

          if (selectedScenario === "scenario1") {

            // Principal + Interest scenario
            currentLoanAmount =
              currentLoanAmount - PrincipleinterestCharged + (currentLoanAmount * (YearInterestRate || 0)) / 100 / 12;
          } else {

            // Interest Only scenario
            currentLoanAmount = loanAmount;
          }
        }

        // Equity
        const equity = currentPropertyValue - (selectedScenario === "scenario1" ? currentLoanAmount : loanAmount);

        // Update values for the next year
        currentRent *= 1 + rentalGrowthRate / 100;
        currentRentalExpenses *= 1 + inflationRate / 100;
        // Gross Rent
        const grossRent = currentRent;



        // Rental Expenses
        const rentalExpenses = currentRentalExpenses;

        // Before Tax Cash Flow
        const beforeTaxCashFlow =
          grossRent -
          rentalExpenses -
          (selectedScenario === "scenario1" ? PrincipleinterestCharged : ((YearInterestRate || 0) / 100));

        // calculate Aust tax rate
        const taxRate = calculateTaxWithDetails(Number(cashFlowAnalysis.employmentIncome?.grossFamilyIncome) + Number(cashFlowAnalysis.employmentIncome?.otherTaxableIncome))

        // After Tax Cash Flow
        const depreciation = cashFlowAnalysis.depreciation.depreciation;
        // const refund = (beforeTaxCashFlow + (- depreciation)) * Number(taxRate.effectiveRate)
        // const afterTaxCashFlow = beforeTaxCashFlow - refund
        // Assuming beforeTaxCashFlow, depreciation, and taxRate.taxSlab are already defined

        const refund = isNaN((beforeTaxCashFlow + (-depreciation)) * (Number(taxRate.taxSlab) / 100))
          ? 0 // Return 0 if the result is NaN
          : (beforeTaxCashFlow + (-depreciation)) * (Number(taxRate.taxSlab) / 100);



        const afterTaxCashFlow = isNaN(beforeTaxCashFlow - refund)
          ? 0
          : beforeTaxCashFlow - refund;


        // Cost/Income pw
        const costIncomePw = beforeTaxCashFlow / 52;
        const PrincipalInterestrepayment = calculatePrincipalInterestRepayment(isNaN(currentLoanAmount) ? 0 : currentLoanAmount, (YearInterestRate || 0), loanTerm);

        const principalForYear = PrincipalInterestrepayment.yearlySchedule.find(schedule => schedule.year === year)?.annualPrincipal;
        const PrincipalInterestRepayment = principalForYear
        const extraPrincipalRepayment = cashFlowAnalysis.cashFlowProjection ? cashFlowAnalysis.cashFlowProjection?.scenario1.cashFlowYears?.find(principal => principal.year === year)?.ExtraPrincipalRepayment : 0

        const InterestOnlyRepayment = calculateInterestOnlyRepayment(currentLoanAmount, (YearInterestRate || 0));
        // const interestCharged = ((YearInterestRate / 100) * currentLoanAmount)
        const interestCharged = selectedScenario === "scenario2" ? Math.round(((YearInterestRate || 0) / 100) * loanAmount * 100) / 100 : Math.round(((YearInterestRate || 0) / 100) * currentLoanAmount * 100) / 100

        // const interestCharged = selectedScenario === "scenario2"
        //   ? (YearInterestRate / 100) * loanAmount
        //   : (YearInterestRate / 100) * currentLoanAmount;

        cashFlowYears.push({
          propertyValue: currentPropertyValue,
          loanAmount: selectedScenario === "scenario2" ? propertyDetails.loanAmount : currentLoanAmount,
          PrincipalInterestRepayment: Number(PrincipalInterestRepayment),
          InterestOnlyRepayment,
          ExtraPrincipalRepayment: Number(extraPrincipalRepayment),
          year,
          equity,
          grossRent,
          rentalExpenses,
          beforeTaxCashFlow,
          afterTaxCashFlow: afterTaxCashFlow,
          InterestRate: (YearInterestRate || 0),
          InterestCharged: interestCharged,
          costIncomePw,
        });


      }

      return cashFlowYears;
    },
    [cashFlowAnalysis.depreciation.depreciation, cashFlowAnalysis.employmentIncome?.grossFamilyIncome, selectedScenario,]
  );

  const calculateCashFlowProjection = useCallback(
    (propertyDetails: PropertyDetailsData, keyAssumptions: KeyAssumptionsData): CashFlowProjectionData => {
      const { loanAmount, interestRate, loanTerm } = propertyDetails;

      // Calculate repayment values
      const principalInterestRepayment = calculatePrincipalInterestRepayment(isNaN(loanAmount) ? 0 : loanAmount, interestRate, loanTerm);
      const interestOnlyRepayment = calculateInterestOnlyRepayment(loanAmount, interestRate);

      // Generate cash flow years for Scenario 1
      const scenario1CashFlowYears = generateCashFlowYearsData(
        propertyDetails,
        keyAssumptions,
        Number(principalInterestRepayment),
        interestOnlyRepayment
      ).map((yearData) => ({
        ...yearData,

      }));;

      // Generate cash flow years for Scenario 2 with a fixed loan amount each year
      const scenario2CashFlowYears = generateCashFlowYearsData(
        { ...propertyDetails, loanAmount }, // Ensure the initial loanAmount is passed in
        keyAssumptions,
        Number(principalInterestRepayment),
        interestOnlyRepayment
      ).map((yearData) => ({
        ...yearData,
        loanAmount: propertyDetails.loanAmount, // Fix loan amount for every year
      }));

      const scenario1: PIScenarioData = {
        principalInterestRepayment: Number(principalInterestRepayment),
        cashFlowYears: scenario1CashFlowYears,
      };

      const scenario2: IOScenarioData = {
        InterestOnlyRepayment: interestOnlyRepayment,
        cashFlowYears: scenario2CashFlowYears,
      };

      return {
        assumptions: keyAssumptions,
        scenario1,
        scenario2,
      };
    },
    [calculatePrincipalInterestRepayment, calculateInterestOnlyRepayment, generateCashFlowYearsData,]
  );

  // const generateCashFlowYearsData = useCallback(
  //   (
  //     propertyDetails: PropertyDetailsData,
  //     keyAssumptions: KeyAssumptionsData,
  //     principalInterestRepayment: number,
  //     interestOnlyRepayment: number
  //   ): CashFlowYearData[] => {
  //     const { estimatedWeeklyRent, annualHoldingCosts, propertyValue, loanAmount, interestRate } = propertyDetails;
  //     const { capitalGrowthRate, rentalGrowthRate, inflationRate } = keyAssumptions;

  //     const cashFlowYears: CashFlowYearData[] = [];

  //     let currentPropertyValue = propertyValue;
  //     let currentLoanAmount = loanAmount;
  //     let currentRent = estimatedWeeklyRent * 52;
  //     let currentRentalExpenses =
  //       annualHoldingCosts.propertyManagementFee +
  //       annualHoldingCosts.lettingFee +
  //       annualHoldingCosts.maintenanceCostPA +
  //       annualHoldingCosts.councilRates +
  //       annualHoldingCosts.waterRates +
  //       annualHoldingCosts.insurance;

  //     for (let year = 1; year <= 30; year++) {
  //       // Property Value
  //       if (year === 1) {
  //         currentPropertyValue = propertyValue;
  //       } else {
  //         currentPropertyValue *= 1 + capitalGrowthRate / 100;
  //       }

  //       // Loan Amount
  //       if (year === 1) {
  //         currentLoanAmount = loanAmount;
  //       } else {
  //         if (selectedScenario === "scenario1") {
  //           // Principal + Interest scenario
  //           currentLoanAmount =
  //             currentLoanAmount - principalInterestRepayment + (currentLoanAmount * interestRate) / 100 / 12;
  //         } else {
  //           // Interest Only scenario
  //           currentLoanAmount = loanAmount;
  //         }
  //       }

  //       // Equity
  //       const equity = currentPropertyValue - currentLoanAmount;

  //       // Gross Rent
  //       const grossRent = currentRent;

  //       // Rental Expenses
  //       const rentalExpenses = currentRentalExpenses;

  //       // Before Tax Cash Flow
  //       const beforeTaxCashFlow =
  //         grossRent -
  //         rentalExpenses -
  //         (selectedScenario === "scenario1" ? principalInterestRepayment : interestOnlyRepayment);

  //       // Cost/Income pw
  //       const costIncomePw = beforeTaxCashFlow / 52;

  //       cashFlowYears.push({
  //         propertyValue,
  //         loanAmount,
  //         year,
  //         equity,
  //         grossRent,
  //         rentalExpenses,
  //         beforeTaxCashFlow,
  //         costIncomePw,
  //       });

  //       // Update values for the next year
  //       currentRent *= 1 + rentalGrowthRate / 100;
  //       currentRentalExpenses *= 1 + inflationRate / 100;
  //     }

  //     return cashFlowYears;
  //   },
  //   [selectedScenario]
  // );

  // const calculateCashFlowProjection = useCallback(
  //   (propertyDetails: PropertyDetailsData, keyAssumptions: KeyAssumptionsData): CashFlowProjectionData => {
  //     const { loanAmount, interestRate, loanTerm } = propertyDetails;
  //     console.log('loanAmount :>> ', loanAmount);
  //     const principalInterestRepayment = calculatePrincipalInterestRepayment(loanAmount, interestRate, loanTerm);
  //     console.log('principalInterestRepayment :>> ', principalInterestRepayment);
  //     const interestOnlyRepayment = calculateInterestOnlyRepayment(loanAmount, interestRate);
  //     console.log('interestOnlyRepayment :>> ', interestOnlyRepayment);
  //     const cashFlowYears: CashFlowYearData[] = generateCashFlowYearsData(
  //       propertyDetails,
  //       keyAssumptions,
  //       principalInterestRepayment,
  //       interestOnlyRepayment



  //       // true
  //     );



  //     const scenario1: PIScenarioData = {
  //       principalInterestRepayment,
  //       cashFlowYears,
  //     };

  //     const scenario2: IOScenarioData = {
  //       InterestOnlyRepayment: interestOnlyRepayment,
  //       cashFlowYears,
  //     };

  //     return {
  //       assumptions: keyAssumptions,
  //       scenario1,
  //       scenario2,
  //     };
  //   },
  //   [calculatePrincipalInterestRepayment, calculateInterestOnlyRepayment, generateCashFlowYearsData, propertyDetails?.loanAmount]
  // );

  // Utility function to compare objects for equality
  const areObjectsEqual = useCallback((obj1: any, obj2: any): boolean => {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
  }, []);

  const cashFlowProjectionHandler = useCallback(() => {
    if (propertyDetails && keyAssumptions) {
      const projection = calculateCashFlowProjection(propertyDetails, keyAssumptions);
      // Check if the new projection is different from the existing one

      if (!areObjectsEqual(projection, cashFlowProjection)) {
        dispatch(updateCashFlowProjection(projection));
      }
    }
  }, [propertyDetails, keyAssumptions, cashFlowProjection, calculateCashFlowProjection, areObjectsEqual, dispatch, cashFlowAnalysis?.depreciation?.depreciation, cashFlowAnalysis?.cashFlowProjection?.scenario1]);
  // const cashFlowProjectionHandler = useCallback(() => {
  //   if (propertyDetails && keyAssumptions) {
  //     // Calculate the projection based on property details and assumptions
  //     const projection = calculateCashFlowProjection(propertyDetails, keyAssumptions);

  //     // If selected scenario is scenario2, update the loanAmount for each year in scenario2
  //     if (selectedScenario === "scenario2") {
  //       projection.scenario2.cashFlowYears = projection.scenario2.cashFlowYears.map((yearData) => ({
  //         ...yearData,
  //         loanAmount: propertyDetails.loanAmount,
  //       }));
  //     }

  //     // Dispatch the update only if the projection has changed
  //     if (!areObjectsEqual(projection, cashFlowProjection)) {
  //       dispatch(updateCashFlowProjection(projection));
  //     }
  //   }
  // }, [propertyDetails, keyAssumptions, selectedScenario, cashFlowProjection, calculateCashFlowProjection, areObjectsEqual, dispatch]);

  useEffect(() => {
    cashFlowProjectionHandler();
  }, [propertyDetails, propertyDetails?.loanAmount, keyAssumptions, selectedScenario, CashFlowProjection, cashFlowAnalysis.depreciation.depreciation,]);


  return (
    <Box id='myChart' >
      <Card
        sx={{
          padding: "18px 20px 20px 20px !important",
          marginBottom: "30px !important",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.10) !important",
          backgroundColor: theme.palette.mode === 'dark' ? '#151632' : '#fff !important',
        }}>
        <Box mb={4}>
          <KeyAssumptions
            keyAssumptions={cashFlowAnalysis.keyAssumptions}
            updateKeyAssumptions={(updates) =>
              dispatch(updateKeyAssumptions(updates))
            }
          />
        </Box>

        <Box display="flex" justifyContent="start">
          <ToggleButtonGroup
            value={selectedScenario}
            exclusive
            onChange={handleScenarioChange}
            sx={{ gap: 2 }}
          >
            <StyledToggleButton
              value="scenario1"
              className={selectedScenario === "scenario1" ? "btn_styleOne active" : "btn_styleOne"}
              onClick={() => {
                setSelectedScenario("scenario1");
                // Remove "active" class from the other button
                document.querySelector(".btn_styleTwo")?.classList.remove("active");
              }}
              sx={{
                backgroundColor: selectedScenario === "scenario1" ? "#5c59fd" : colors.primary[900],
                borderColor: colors.primary[400],
                color: selectedScenario === "scenario1" ? "#fff" : "#4cceac",
                transition: "background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease", // Smooth transition for styles
                cursor: "pointer",
              }}
              disabled={selectedScenario === "scenario1"}
            >
              Principal & Interest
            </StyledToggleButton>

            <StyledToggleButton
              value="scenario2"
              className={selectedScenario === "scenario2" ? "btn_styleTwo active" : "btn_styleTwo"}
              onClick={() => {
                setSelectedScenario("scenario2");
                // Remove "active" class from the other button
                document?.querySelector(".btn_styleOne")?.classList?.remove("active");
              }}
              sx={{
                backgroundColor: selectedScenario === "scenario2" ? "#5c59fd" : colors.primary[900],
                borderColor: colors.primary[400],
                color: selectedScenario === "scenario2" ? "#fff" : "#4cceac",
                transition: "background-color 0.3s ease, border-color 0.3s ease, color 0.3s ease", // Smooth transition for styles
                cursor: "pointer",
              }}
              disabled={selectedScenario === "scenario2"}
            >
              Interest Only
            </StyledToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Card>
      {isLoading ? (
        <Shimmer /> // Show the custom loader during transition
      ) : selectedScenario === "scenario1" && cashFlowProjection?.scenario1 ? (
        <Suspense fallback={<Shimmer />}>
          <CashFlowScenario
            title="Principal & Interest Loan"
            scenario={cashFlowProjection.scenario1}
            loanType="principal-interest"
          />
        </Suspense>
      ) : selectedScenario === "scenario2" && cashFlowProjection?.scenario2 ? (
        <Suspense fallback={<Shimmer />}>
          <CashFlowScenario
            title="Interest Only Loan"
            scenario={cashFlowProjection.scenario2}
            loanType="interest-only"
          />
        </Suspense>
      ) : (
        <Typography>No data available for the selected scenario</Typography>
      )}
    </Box>
  );
};

export default CashFlowProjection;
// Loader.js

const Loader = () => {
  const loaderStyle: any = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    fontFamily: 'Arial, sans-serif',
  };

  const spinningCircleStyle = {
    border: '5px solid #f3f3f3',
    borderTop: '5px solid #3498db',
    borderRadius: '50%',
    width: '50px',
    height: '50px',
    animation: 'spin 1s linear infinite',
  };

  const textStyle = {
    marginTop: '10px',
    fontSize: '16px',
    color: '#3498db',
  };

  // Keyframes for the spinner animation
  const keyframesStyle = {
    '@keyframes spin': {
      '0%': { transform: 'rotate(0deg)' },
      '100%': { transform: 'rotate(360deg)' },
    }
  };

  // Inject keyframes style into the document
  const styleSheet = document.styleSheets[0];
  const keyframe = `@keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }`;
  styleSheet.insertRule(keyframe, styleSheet.cssRules.length);

  return (
    <div style={loaderStyle}>
      <div style={spinningCircleStyle}></div>
      <p style={textStyle}>Loading...</p>
    </div>
  );
};


const Shimmer = () => {
  return (
    <>
      <style>
        {`
          @keyframes shimmer {
            0% {
              background-position: -200% 0;
            }
            100% {
              background-position: 200% 0;
            }
          }
        `}
      </style>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <div
          style={{
            height: '200px', // Increased height
            width: '100%',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
            animation: 'shimmer 1s infinite linear',
            borderRadius: '5px',
          }}
        ></div>
        <div
          style={{
            height: '200px', // Increased height
            width: '100%',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
            animation: 'shimmer 1s infinite linear',
            borderRadius: '5px',
          }}
        ></div>
        <div
          style={{
            height: '200px', // Increased height
            width: '100%',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
            animation: 'shimmer 1s infinite linear',
            borderRadius: '5px',
          }}
        ></div>
        <div
          style={{
            height: '200px', // Increased height
            width: '100%',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
            animation: 'shimmer 1s infinite linear',
            borderRadius: '5px',
          }}
        ></div>
        <div
          style={{
            height: '200px', // Increased height
            width: '100%',
            background: 'linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%)',
            backgroundSize: '200% 100%',
            animation: 'shimmer 1s infinite linear',
            borderRadius: '5px',
          }}
        ></div>
      </div>
    </>
  );
};
