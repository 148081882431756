import { useState, useEffect, useRef } from 'react';
import { Auth } from 'aws-amplify';
import AuthenticatedAPI from '../../auth_helper';
import { string } from 'yup';
import { toast } from 'react-toastify';
const API_URL = 'https://api.nexu.com.au/properties';

const TOKEN = 'a7960e3e-93f1-46aa-93ca-04396fa6c605';

export const apiKey = 'AIzaSyCPdDTAJGEyL9EEStTyOZ5iFt5Y85uBuyg'
interface Match {
  address: {
    streetAddress: string;
    locality: string;
    state: string;
    postcode: string;
  };
  id: string;
  selectedAddresses: string[]

}

interface LmiRate {
  lvr: number;
  rate: number;
}
export interface Result {
  match: Match;
  score: number;

}

export interface PropertyData {
  results: Result[];
  request: {
    suggest: any;
    propertyType: any[];
    filters: any[];
    sortBy: any[];
    limit: number;
    // other properties
  };
  _meta: {
    count: number;
  };
}

interface StreetViewImageProps {
  addresses: string[];
}

interface TaxResult {
  income: number;
  tax: number;
  effectiveRate: number;
  taxSlab: number; // Now returns the percentage rate as a number
}

const useFetchProperties = (address: string) => {
  const [propertyData, setData] = useState<PropertyData | null>(null);
  console.log('propertyData', propertyData)
  const [error, setError] = useState<Error | null>(null);
  const [propertyLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (address?.length < 3) {
      setData(null);
      return;
    }

    const fetchProperties = async () => {
      setLoading(true);
      setError(null);

      try {
        await Auth?.currentAuthenticatedUser().then(async (res) => {

          const response = await AuthenticatedAPI.request(
            'InvestmentAPI',
            'get',
            `/api/fetch-properties/${address}`
          );
          // const url = `${API_URL}/suggest?address=${address}&cache=true&active-only=true&limit=10`;
          // const options = {
          //   method: 'GET',
          //   headers: {
          //     'accept': 'application/json',
          //     'Authorization': `Bearer ${TOKEN}`
          //   }
          // };

          // try {
          //   const response = await fetch(url, options);
          if (!response.success) {
            console.log('Network response was not ok');
          }
          // const result = await response.json();
          setData(response.data);
        })

      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }

    };

    fetchProperties();
  }, [address]);

  return { propertyData, propertyLoading, error };
};

const useFetchpropertyavm = (propertyID: string) => {
  const [propertyavmdata, setData] = useState<any>(null);
  const [propertyavmloading, setLoading] = useState<boolean>(false);
  const [propertyavmerror, setError] = useState<Error | null>(null);
  useEffect(() => {
    if (!propertyID) {
      setData(null);
      return;
    }

    const fetchPropertiesavm = async () => {
      setLoading(true);
      setError(null);
      try {
        await Auth?.currentAuthenticatedUser().then(async (res) => {

          const response = await AuthenticatedAPI.request(
            'InvestmentAPI',
            'get',
            `/api/fetch-properties-avm/${propertyID}`
          );
          // const url = `${API_URL}/suggest?address=${address}&cache=true&active-only=true&limit=10`;
          // const options = {
          //   method: 'GET',
          //   headers: {
          //     'accept': 'application/json',
          //     'Authorization': `Bearer ${TOKEN}`
          //   }
          // };

          // try {
          //   const response = await fetch(url, options);
          if (!response.success) {
            console.log('Network response was not ok');
          }
          // const result = await response.json();
          setData(response.data);
          // })
          // const url = `${API_URL}/${propertyID}/avm`;
          // const options = {
          //   method: 'GET',
          //   headers: {
          //     'accept': 'application/json',
          //     'Authorization': `Bearer ${TOKEN}`
          //   }
          // };

          // try {
          //   const response = await fetch(url, options);
          //   if (!response.ok) {
          //     throw new Error('Network response was not ok');
          //   }
          //   const result = await response.json();
          //   setData(result);
        })
        // }
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchPropertiesavm()


  }, [propertyID]); // Empty dependency array means this will run once after the initial render

  return { propertyavmdata, propertyavmloading, propertyavmerror };
};
export const AutoUpdateFetchpropertyavm = async (propertyID: string) => {
  // setLoading(true);
  // setError(null);


  try {
    await Auth?.currentAuthenticatedUser().then(async (res) => {

      const response = await AuthenticatedAPI.request(
        'InvestmentAPI',
        'get',
        `/api/fetch-properties-avm/${propertyID}`
      );
      // const url = `${API_URL}/suggest?address=${address}&cache=true&active-only=true&limit=10`;
      // const options = {
      //   method: 'GET',
      //   headers: {
      //     'accept': 'application/json',
      //     'Authorization': `Bearer ${TOKEN}`
      //   }
      // };

      // try {
      //   const response = await fetch(url, options);
      if (!response.success) {
        console.log('Network response was not ok');
      }
      // const result = await response.json();
      return response.data;
      // })
      // const url = `${API_URL}/${propertyID}/avm`;
      // const options = {
      //   method: 'GET',
      //   headers: {
      //     'accept': 'application/json',
      //     'Authorization': `Bearer ${TOKEN}`
      //   }
      // };

      // try {
      //   const response = await fetch(url, options);
      //   if (!response.ok) {
      //     throw new Error('Network response was not ok');
      //   }
      //   const result = await response.json();
      //   setData(result);
    })
  } catch (error) {
  } finally {
  }
};


const useFetchpropertDetailed = (propertyID: string) => {
  const [propertyDetaileddata, setData] = useState<any>(null);
  const [propertyDetailedloading, setLoading] = useState<boolean>(false);
  const [propertyDetailederror, setError] = useState<Error | null>(null);
  useEffect(() => {
    if (!propertyID) {
      setData(null);
      return;
    }

    const fetchPropertiesDetails = async () => {
      setLoading(true);
      setError(null);
      try {
        await Auth?.currentAuthenticatedUser().then(async (res) => {

          const response = await AuthenticatedAPI.request(
            'InvestmentAPI',
            'get',
            `/api/fetch-property-details/${propertyID}`
          );
          // const url = `${API_URL}/suggest?address=${address}&cache=true&active-only=true&limit=10`;
          // const options = {
          //   method: 'GET',
          //   headers: {
          //     'accept': 'application/json',
          //     'Authorization': `Bearer ${TOKEN}`
          //   }
          // };

          // try {
          //   const response = await fetch(url, options);
          if (!response.success) {
            console.log('Network response was not ok');
          }
          // const result = await response.json();
          setData(response.data);
          // })
          // const url = `${API_URL}/${propertyID}/avm`;
          // const options = {
          //   method: 'GET',
          //   headers: {
          //     'accept': 'application/json',
          //     'Authorization': `Bearer ${TOKEN}`
          //   }
          // };

          // try {
          //   const response = await fetch(url, options);
          //   if (!response.ok) {
          //     throw new Error('Network response was not ok');
          //   }
          //   const result = await response.json();
          //   setData(result);
        })

        // const url = `${API_URL}/${propertyID}/_detailed`;
        // const options = {
        //   method: 'GET',
        //   headers: {
        //     'accept': 'application/json',
        //     'Authorization': `Bearer ${TOKEN}`
        //   }
        // };

        // try {
        //   const response = await fetch(url, options);
        //   if (!response.ok) {
        //     throw new Error('Network response was not ok');
        //   }
        //   const result = await response.json();
        //   setData(result);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchPropertiesDetails()


  }, [propertyID]); // Empty dependency array means this will run once after the initial render

  return { propertyDetaileddata, propertyDetailedloading, propertyDetailederror };
};
const useFetchpropertCoordinates = (propertyAddress: string) => {
  const [propertyCoordinates, setData] = useState<any>(null);
  const [propertyCoordinatesloading, setLoading] = useState<boolean>(false);
  const [propertyCoordinateserror, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!propertyAddress) {
      setData(null);
      return;
    }

    const fetchPropertiescoordinates = async () => {
      setLoading(true);
      setError(null);

      try {
        await Auth?.currentAuthenticatedUser().then(async (res) => {

          const response = await AuthenticatedAPI.request(
            'InvestmentAPI',
            'get',
            `/api/fetch-property-coordinates/${propertyAddress}`
          );
          // const url = `${API_URL}/suggest?address=${address}&cache=true&active-only=true&limit=10`;
          // const options = {
          //   method: 'GET',
          //   headers: {
          //     'accept': 'application/json',
          //     'Authorization': `Bearer ${TOKEN}`
          //   }
          // };

          // try {
          //   const response = await fetch(url, options);
          if (!response.success) {
            console.log('Network response was not ok');
          }
          // const result = await response.json();
          setData(response.data);
          // })
          // const url = `${API_URL}/${propertyID}/avm`;
          // const options = {
          //   method: 'GET',
          //   headers: {
          //     'accept': 'application/json',
          //     'Authorization': `Bearer ${TOKEN}`
          //   }
          // };

          // try {
          //   const response = await fetch(url, options);
          //   if (!response.ok) {
          //     throw new Error('Network response was not ok');
          //   }
          //   const result = await response.json();
          //   setData(result);
        })
        // const url = `${API_URL}/geocode?address=${propertyAddress}&limit=3`;
        // const options = {
        //   method: 'GET',
        //   headers: {
        //     'accept': 'application/json',
        //     'Authorization': `Bearer ${TOKEN}`
        //   }
        // };

        // try {
        //   const response = await fetch(url, options);
        //   if (!response.ok) {
        //     throw new Error('Network response was not ok');
        //   }
        //   const result = await response.json();
        //   setData(result);
      } catch (error) {
        setError(error as Error);
      } finally {
        setLoading(false);
      }
    };
    fetchPropertiescoordinates()


  }, [propertyAddress]); // Empty dependency array means this will run once after the initial render

  return { propertyCoordinates, propertyCoordinatesloading, propertyCoordinateserror };
};

const useStreetViewImage = (address: string) => {

  const [imageUrl, setImageUrl] = useState<any>(null);;

  useEffect(() => {
    const fetchStreetViewImage = async () => {
      if (!address || !apiKey) return;

      const params = {
        size: '600x300', // Image size
        location: address, // Address
        fov: '80', // Field of view
        heading: '70', // Heading
        pitch: '0', // Pitch
        key: apiKey,
      };

      const url = await 'https://maps.googleapis.com/maps/api/streetview';
      const queryParams = new URLSearchParams(params).toString();
      const imageUrl = `${url}?${queryParams}`;
      setImageUrl(imageUrl);
    };

    fetchStreetViewImage();
  }, [address]);

  return imageUrl;
};

const deleteProperty = async (id: string) => {

  try {
    const userData = await Auth?.currentAuthenticatedUser().then(async (res) => {

      const response = await AuthenticatedAPI.request(
        'InvestmentAPI',
        'del',
        `/api/property/${id}`
      );

      if (!response) {
        throw new Error('Failed to delete property');
      } else {
        toast.success('Property deleted successfully');
      }
      return response
    });
  } catch (error) {
    toast.error('Error deleting property');
    console.error('Error fetching user profile:', error);
  }
}

export { useFetchProperties, useFetchpropertyavm, deleteProperty, useFetchpropertDetailed, useFetchpropertCoordinates, useStreetViewImage };


export const formatNumber = (num: number) => {
  if (num >= 1000000 || num <= -1000000) {
    return (num / 1000000).toFixed(0) + 'M'; // 1,000,000 -> 1.0M
  } else if (num >= 1000 || num <= -1000) {
    return (num / 1000).toFixed(0) + 'K'; // 1,000 -> 1.0K
  }
  return num.toString(); // below 1,000 stays the same
};

export const StreetViewImage = (addresses: string[]) => {
  const [images, setImages] = useState<string[]>([]);
  const prevAddressesRef = useRef<string[]>([]);

  useEffect(() => {
    const fetchStreetViewImage = async (address: string): Promise<string | null> => {
      if (!address || !apiKey) return null;

      const params = {
        size: '600x300', // Image size
        location: address, // Address
        fov: '80', // Field of view
        heading: '70', // Heading
        pitch: '0', // Pitch
        key: apiKey,
      };

      const url = 'https://maps.googleapis.com/maps/api/streetview';
      const queryParams = new URLSearchParams(params).toString();
      const imageUrl = `${url}?${queryParams}`;

      try {
        const response = await fetch(imageUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch image');
        }
        return imageUrl;
      } catch (error) {
        console.error('Error fetching image:', error);
        return null;
      }
    };

    const fetchAllImages = async () => {
      try {
        if (!Array.isArray(addresses)) {
          throw new Error('Addresses is not an array');
        }

        const promises = addresses.map((address: string) => fetchStreetViewImage(address));
        const results = await Promise.all(promises);
        const validResults = results?.filter(Boolean) as string[];
        setImages(prevValue => {
          const newImages = validResults?.filter(result => !prevValue.includes(result));
          return [...prevValue, ...newImages];
        });
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    const prevAddresses = prevAddressesRef.current;
    if (
      addresses.length > 0 &&
      (addresses.length !== prevAddresses.length ||
        !addresses.every((address, index) => address === prevAddresses[index]))
    ) {
      fetchAllImages();
      prevAddressesRef.current = addresses;
    }
  }, [addresses]);
  return images;
};

const lmiRates: LmiRate[] = [
  { lvr: 81, rate: 0.57 },
  { lvr: 82, rate: 0.57 },
  { lvr: 83, rate: 0.70 },
  { lvr: 84, rate: 0.83 },
  { lvr: 85, rate: 0.97 },
  { lvr: 86, rate: 1.08 },
  { lvr: 87, rate: 1.15 },
  { lvr: 88, rate: 1.31 },
  { lvr: 89, rate: 1.62 },
  { lvr: 90, rate: 1.87 },
  { lvr: 91, rate: 2.62 },
  { lvr: 92, rate: 2.67 },
  { lvr: 93, rate: 3.03 },
  { lvr: 94, rate: 3.03 },
  { lvr: 95, rate: 3.35 },
];
export const getLmiRate = (lvr: number) => {
  const rate = lmiRates.find(rate => lvr <= rate.lvr);
  return rate ? rate.rate : null; // Return null if no rate found
};


//   calculate stampduty


// type PropertyType = 'residential' | 'commercial' | 'primary-production';
// type BuyerType = 'first-home' | 'owner-occupier' | 'investor' | 'foreign';

// interface StampDutyParams {
//   propertyValue: number;
//   propertyType: PropertyType;
//   buyerType: BuyerType;
// }

// function calculateNSWStampDuty({ propertyValue, buyerType }: StampDutyParams): number {
//   let duty = 0;

//   if (propertyValue <= 14000) {
//     duty = propertyValue * 0.0125;
//   } else if (propertyValue <= 30000) {
//     duty = 175 + (propertyValue - 14000) * 0.015;
//   } else if (propertyValue <= 80000) {
//     duty = 415 + (propertyValue - 30000) * 0.0175;
//   } else if (propertyValue <= 300000) {
//     duty = 1290 + (propertyValue - 80000) * 0.035;
//   } else if (propertyValue <= 1000000) {
//     duty = 8990 + (propertyValue - 300000) * 0.045;
//   } else if (propertyValue <= 3000000) {
//     duty = 40490 + (propertyValue - 1000000) * 0.055;
//   } else {
//     duty = 150490 + (propertyValue - 3000000) * 0.07;
//   }

//   if (buyerType === 'foreign') {
//     duty += propertyValue * 0.08;
//   }

//   return duty;
// }

// function calculateVICStampDuty({ propertyValue, buyerType }: StampDutyParams): number {
//   let duty = 0;

//   if (propertyValue <= 25000) {
//     duty = propertyValue * 0.014;
//   } else if (propertyValue <= 130000) {
//     duty = 350 + (propertyValue - 25000) * 0.024;
//   } else if (propertyValue <= 960000) {
//     duty = 2870 + (propertyValue - 130000) * 0.06;
//   } else if (propertyValue <= 2000000) {
//     duty = propertyValue * 0.055;
//   } else {
//     duty = 110000 + (propertyValue - 2000000) * 0.065;
//   }

//   if (buyerType === 'foreign') {
//     duty += propertyValue * 0.08;
//   }

//   return duty;
// }

// function calculateQLDStampDuty({ propertyValue, buyerType }: StampDutyParams): number {
//   let duty = 0;

//   if (propertyValue <= 5000) {
//     duty = 0;
//   } else if (propertyValue <= 75000) {
//     duty = (propertyValue - 5000) * 0.015;
//   } else if (propertyValue <= 540000) {
//     duty = 1050 + (propertyValue - 75000) * 0.035;
//   } else if (propertyValue <= 1000000) {
//     duty = 17325 + (propertyValue - 540000) * 0.045;
//   } else {
//     duty = 38025 + (propertyValue - 1000000) * 0.0575;
//   }

//   if (buyerType === 'foreign') {
//     duty += propertyValue * 0.07;
//   }

//   return duty;
// }

// function calculateWAStampDuty({ propertyValue, buyerType }: StampDutyParams): number {
//   let duty = 0;

//   if (propertyValue <= 120000) {
//     duty = propertyValue * 0.019;
//   } else if (propertyValue <= 150000) {
//     duty = 2280 + (propertyValue - 120000) * 0.0285;
//   } else if (propertyValue <= 360000) {
//     duty = 3135 + (propertyValue - 150000) * 0.038;
//   } else if (propertyValue <= 725000) {
//     duty = 11115 + (propertyValue - 360000) * 0.0475;
//   } else {
//     duty = 28453 + (propertyValue - 725000) * 0.0515;
//   }

//   if (buyerType === 'foreign') {
//     duty += propertyValue * 0.07;
//   }

//   return duty;
// }

// function calculateSAStampDuty({ propertyValue }: StampDutyParams): number {
//   let duty = 0;

//   if (propertyValue <= 12000) {
//     duty = propertyValue * 0.01;
//   } else if (propertyValue <= 30000) {
//     duty = 120 + (propertyValue - 12000) * 0.02;
//   } else if (propertyValue <= 50000) {
//     duty = 480 + (propertyValue - 30000) * 0.03;
//   } else if (propertyValue <= 100000) {
//     duty = 1080 + (propertyValue - 50000) * 0.035;
//   } else if (propertyValue <= 200000) {
//     duty = 2830 + (propertyValue - 100000) * 0.04;
//   } else if (propertyValue <= 250000) {
//     duty = 6830 + (propertyValue - 200000) * 0.0425;
//   } else if (propertyValue <= 300000) {
//     duty = 8955 + (propertyValue - 250000) * 0.0475;
//   } else if (propertyValue <= 500000) {
//     duty = 11330 + (propertyValue - 300000) * 0.05;
//   } else {
//     duty = 21330 + (propertyValue - 500000) * 0.055;
//   }

//   return duty;
// }

// function calculateTASStampDuty({ propertyValue }: StampDutyParams): number {
//   let duty = 0;

//   if (propertyValue <= 3000) {
//     duty = 50;
//   } else if (propertyValue <= 25000) {
//     duty = 50 + (propertyValue - 3000) * 0.0175;
//   } else if (propertyValue <= 75000) {
//     duty = 435 + (propertyValue - 25000) * 0.0225;
//   } else if (propertyValue <= 200000) {
//     duty = 1560 + (propertyValue - 75000) * 0.035;
//   } else if (propertyValue <= 375000) {
//     duty = 5935 + (propertyValue - 200000) * 0.04;
//   } else if (propertyValue <= 725000) {
//     duty = 12935 + (propertyValue - 375000) * 0.0425;
//   } else {
//     duty = 27810 + (propertyValue - 725000) * 0.045;
//   }

//   return duty;
// }

// function calculateACTStampDuty({ propertyValue }: StampDutyParams): number {
//   let duty = 0;

//   if (propertyValue <= 260000) {
//     duty = propertyValue * 0.0049;
//   } else if (propertyValue <= 300000) {
//     duty = 1274 + (propertyValue - 260000) * 0.022;
//   } else if (propertyValue <= 500000) {
//     duty = 2154 + (propertyValue - 300000) * 0.034;
//   } else if (propertyValue <= 750000) {
//     duty = 8954 + (propertyValue - 500000) * 0.0432;
//   } else if (propertyValue <= 1000000) {
//     duty = 19754 + (propertyValue - 750000) * 0.059;
//   } else if (propertyValue <= 1455000) {
//     duty = 34504 + (propertyValue - 1000000) * 0.064;
//   } else {
//     duty = propertyValue * 0.0454;
//   }

//   return duty;
// }

// function calculateNTStampDuty({ propertyValue }: StampDutyParams): number {
//   if (propertyValue <= 525000) {
//     const V = propertyValue / 1000;
//     return (0.06571441 * V * V) + (15 * V);
//   } else {
//     return propertyValue * 0.0495;
//   }
// }

// function calculateStampDuty(state: string, params: StampDutyParams): number {
//   switch (state.toUpperCase()) {
//     case 'NSW':
//       return calculateNSWStampDuty(params);
//     case 'VIC':
//       return calculateVICStampDuty(params);
//     case 'QLD':
//       return calculateQLDStampDuty(params);
//     case 'WA':
//       return calculateWAStampDuty(params);
//     case 'SA':
//       return calculateSAStampDuty(params);
//     case 'TAS':
//       return calculateTASStampDuty(params);
//     case 'ACT':
//       return calculateACTStampDuty(params);
//     case 'NT':
//       return calculateNTStampDuty(params);
//     default:
//       throw new Error('Unsupported state');
//   }
// }

// // Usage example
// export const StampDuty =(propertyValue:number)=>{
// const stampDutyRes = calculateStampDuty('NSW', {
//   propertyValue: propertyValue,
//   propertyType: 'residential',
//   buyerType: 'owner-occupier'
// }); 
//   return stampDutyRes.toFixed(0)
// } 
// console.log(Stamp duty: $${stampDuty.toFixed(2)});

export type State = 'NSW' | 'QLD' | 'VIC' | 'TAS' | 'SA' | 'WA' | 'ACT' | 'NT';

interface DutyBracket {
  min: number;
  max: number;
  fromPreviousBracket: number;
  rate: number;
}

const dutyTables: Record<State, DutyBracket[]> = {
  NSW: [
    { min: 0, max: 14000, fromPreviousBracket: 0, rate: 1.25 },
    { min: 14001, max: 31000, fromPreviousBracket: 175, rate: 1.50 },
    { min: 31001, max: 83000, fromPreviousBracket: 430, rate: 1.75 },
    { min: 83001, max: 313000, fromPreviousBracket: 1340, rate: 3.50 },
    { min: 313001, max: 1043000, fromPreviousBracket: 9390, rate: 4.50 },
    { min: 1043001, max: 100000000, fromPreviousBracket: 42240, rate: 5.50 },
    { min: 100000001, max: 200000000, fromPreviousBracket: 42240, rate: 5.50 }
  ],
  QLD: [
    { min: 0, max: 5000, fromPreviousBracket: 0, rate: 0 },
    { min: 5001, max: 75000, fromPreviousBracket: 0, rate: 1.50 },
    { min: 75001, max: 540000, fromPreviousBracket: 1050, rate: 3.50 },
    { min: 540001, max: 1000000, fromPreviousBracket: 17325, rate: 4.50 },
    { min: 1000001, max: 100000000, fromPreviousBracket: 38025, rate: 5.75 },
    { min: 100000001, max: 200000000, fromPreviousBracket: 38025, rate: 5.75 }
  ],
  VIC: [
    { min: 0, max: 25000, fromPreviousBracket: 0, rate: 1.40 },
    { min: 25001, max: 130000, fromPreviousBracket: 350, rate: 2.40 },
    { min: 130001, max: 960000, fromPreviousBracket: 2870, rate: 6.00 },
    { min: 960001, max: 2000000, fromPreviousBracket: 52800, rate: 5.50 },
    { min: 2000001, max: 200000000, fromPreviousBracket: 110000, rate: 6.50 }
  ],
  TAS: [
    { min: 0, max: 3000, fromPreviousBracket: 50, rate: 0.00 },
    { min: 3001, max: 25000, fromPreviousBracket: 50, rate: 1.75 },
    { min: 25001, max: 75000, fromPreviousBracket: 435, rate: 2.25 },
    { min: 75001, max: 200000, fromPreviousBracket: 1560, rate: 3.50 },
    { min: 200001, max: 375000, fromPreviousBracket: 5935, rate: 4.00 },
    { min: 375001, max: 725000, fromPreviousBracket: 12935, rate: 4.25 },
    { min: 725001, max: 100000000, fromPreviousBracket: 27810, rate: 4.50 },
    { min: 100000001, max: 200000000, fromPreviousBracket: 27810, rate: 4.50 }
  ],
  SA: [
    { min: 0, max: 12000, fromPreviousBracket: 0, rate: 1.00 },
    { min: 12001, max: 30000, fromPreviousBracket: 120, rate: 2.00 },
    { min: 30001, max: 50000, fromPreviousBracket: 480, rate: 3.00 },
    { min: 50001, max: 100000, fromPreviousBracket: 1080, rate: 3.50 },
    { min: 100001, max: 200000, fromPreviousBracket: 2830, rate: 4.00 },
    { min: 200001, max: 250000, fromPreviousBracket: 6830, rate: 4.25 },
    { min: 250001, max: 300000, fromPreviousBracket: 8955, rate: 4.75 },
    { min: 300001, max: 500000, fromPreviousBracket: 11330, rate: 5.00 },
    { min: 500001, max: Infinity, fromPreviousBracket: 21330, rate: 5.50 }
  ],
  WA: [
    { min: 0, max: 120000, fromPreviousBracket: 0, rate: 1.90 },
    { min: 120001, max: 150000, fromPreviousBracket: 2280, rate: 2.85 },
    { min: 150001, max: 360000, fromPreviousBracket: 3135, rate: 3.80 },
    { min: 360001, max: 725000, fromPreviousBracket: 11115, rate: 4.75 },
    { min: 725001, max: Infinity, fromPreviousBracket: 28453, rate: 5.15 }
  ],
  ACT: [
    { min: 0, max: 12000, fromPreviousBracket: 0, rate: 1.00 },
    { min: 12001, max: 30000, fromPreviousBracket: 120, rate: 2.00 },
    { min: 30001, max: 50000, fromPreviousBracket: 480, rate: 3.00 },
    { min: 50001, max: 100000, fromPreviousBracket: 1080, rate: 3.50 },
    { min: 100001, max: 200000, fromPreviousBracket: 2830, rate: 4.00 },
    { min: 200001, max: 250000, fromPreviousBracket: 6830, rate: 4.25 },
    { min: 250001, max: 300000, fromPreviousBracket: 8955, rate: 4.75 },
    { min: 300001, max: 500000, fromPreviousBracket: 11330, rate: 5.00 },
    { min: 500001, max: Infinity, fromPreviousBracket: 21330, rate: 5.50 }
  ],
  NT: [
    { min: 0, max: 120000, fromPreviousBracket: 0, rate: 1.90 },
    { min: 120001, max: 150000, fromPreviousBracket: 2280, rate: 2.85 },
    { min: 150001, max: 360000, fromPreviousBracket: 3135, rate: 3.80 },
    { min: 360001, max: 725000, fromPreviousBracket: 11115, rate: 4.75 },
    { min: 725001, max: Infinity, fromPreviousBracket: 28453, rate: 5.15 }
  ]
};

export function StampDuty(state: State, purchasePrice: number): number {
  if (!state) {
    return 0
  }
  const brackets = dutyTables[state];
  const bracket = brackets?.find(b => purchasePrice > b.min && purchasePrice <= b.max);

  if (!bracket) {
    throw new Error(`Unable to find appropriate bracket for purchase price ${purchasePrice} in ${state}`);
  }

  const { fromPreviousBracket, rate, min } = bracket;
  const dutiableValue = purchasePrice - min;
  const duty = fromPreviousBracket + (dutiableValue * rate / 100);

  return Math.round(duty * 100) / 100; // Round to 2 decimal places
}

// Example usage for all states:
// console.log('NSW:', calculateStampDuty('NSW', 500000));

export const formatNumberWithCommas = (value: any) => {
  if (isNaN(value)) return "0";
  const fixedValue = Number(value).toFixed(0); // Ensure no decimal places
  return Number(fixedValue).toLocaleString('en-US');
};

export const vacantPerioudGrossRent = (value: number) => {
  if (value > 1) {
    return 52 - value
  } else {
    return 52
  }
}


// enum State {
//   Victoria = 'Victoria',
//   NewSouthWales = 'New South Wales',
//   Queensland = 'Queensland',
//   WesternAustralia = 'Western Australia',
//   SouthAustralia = 'South Australia',
//   Tasmania = 'Tasmania',
//   AustralianCapitalTerritory = 'Australian Capital Territory',
//   NorthernTerritory = 'Northern Territory'
// }



export enum NSWTransferType {
  General = 'General',
  Covenant = 'Covenant',
  Easement = 'Easement',
}

export function calculateTransferFee(state: State, propertyValue: number, options?: {
  isElectronicLodgement?: boolean,
  nswTransferType?: NSWTransferType
}): number {
  switch (state) {
    case 'VIC':
      // case States.Victoria:
      const baseFee = options?.isElectronicLodgement ? 93.4 : 116.8;
      if (propertyValue <= 1000000) {
        return baseFee;
      } else {
        const excessValue = propertyValue - 1000000;
        const additionalFee = Math.ceil(excessValue / 1000) * 2.34;
        return baseFee + additionalFee;
      }

    case 'NSW':
      // case States.NewSouthWales:
      switch (options?.nswTransferType) {
        case NSWTransferType.Covenant:
        case NSWTransferType.Easement:
          return 312.64;
        default:
          return 156.55;
      }

    case 'QLD':
      // case States.Queensland:
      if (propertyValue <= 180000) return 227;
      if (propertyValue <= 350000) return 243;
      if (propertyValue <= 540000) return 259;
      if (propertyValue <= 1000000) return 275;
      return 311;

    case 'WA':
      // case States.WesternAustralia:
      return 187.6;

    case 'SA':
      // case States.SouthAustralia:
      return 177;

    case 'TAS':
      // case States.Tasmania:
      return 144.72;

    case 'ACT':
      // case States.AustralianCapitalTerritory:
      return 160;

    case 'NT':
      // case States.NorthernTerritory:
      return 154;

    default:
      return 0
  }
}

// type NSWTransferType = 'General' | 'Covenant' | 'Easement';
// type States = {
//   "Victoria": 'Victoria',
//   "NewSouthWales": 'New South Wales',
//   "Queensland": 'Queensland',
//   "WesternAustralia": 'Western Australia',
//   "SouthAustralia": 'South Australia',
//   "Tasmania": 'Tasmania',
//   "AustralianCapitalTerritory": 'Australian Capital Territory',
//   "NorthernTerritory": 'Northern Territory'
// }
// export function calculateTransferFee(state: States, propertyValue: number, options?: {
//   isElectronicLodgement?: boolean,
//   nswTransferType?: NSWTransferType
// }): number {
//   switch (state) {
//     case States.Victoria:
//       const baseFee = options?.isElectronicLodgement ? 93.4 : 116.8;
//       if (propertyValue <= 1000000) {
//         return baseFee;
//       } else {
//         const excessValue = propertyValue - 1000000;
//         const additionalFee = Math.ceil(excessValue / 1000) * 2.34;
//         return baseFee + additionalFee;
//       }

//     case States.NewSouthWales:
//       switch (options?.nswTransferType) {
//         case 'Covenant':
//         case 'Easement':
//           return 312.64;
//         default:
//           return 156.55;
//       }

//     case States.Queensland:
//       if (propertyValue <= 180000) return 227;
//       if (propertyValue <= 350000) return 243;
//       if (propertyValue <= 540000) return 259;
//       if (propertyValue <= 1000000) return 275;
//       return 311;

//     case States.WesternAustralia:
//       return 187.6;

//     case States.SouthAustralia:
//       return 177;

//     case States.Tasmania:
//       return 144.72;

//     case States.AustralianCapitalTerritory:
//       return 160;

//     case States.NorthernTerritory:
//       return 154;

//     default:
//       throw new Error('Invalid state');
//   }
// }

// <<<<<<<  edcb957f-c898-4672-a1fa-aaa1b9b4cd02  >>>>>>>
// export const FetchUserProfile = async ({ investorID }: { investorID?: string }) => {
export async function FetchUserProfile() {
  try {
    const user = await Auth?.currentAuthenticatedUser();
    const response = await AuthenticatedAPI.request(
      'InvestmentAPI',
      'get',
      `/api/profile/${user.username}`
    );

    if (!response) {
      throw new Error('Failed to fetch user profile');
    }

    return response.data;  // Ensure response data is returned
  } catch (error) {
    console.error('Error fetching user profile:', error);
  }
}

/**
 * Calculates Australian resident tax for the 2024-25 financial year
 * @param income Annual taxable income in AUD
 * @returns The calculated tax amount in AUD
 */


function calculateResidentTax(income: number): { tax: number; taxSlab: number } {
  if (income < 0) {
    throw new Error('Income cannot be negative');
  }

  if (income <= 18200) {
    return { tax: 0, taxSlab: 0 }; // No tax for income up to $18,200
  }

  if (income <= 45000) {
    const tax = (income - 18200) * 0.16;
    return { tax, taxSlab: 16 }; // 16% tax slab for $18,201 to $45,000
  }

  if (income <= 135000) {
    const tax = 4288 + (income - 45000) * 0.30;
    return { tax, taxSlab: 30 }; // 30% tax slab for $45,001 to $135,000
  }

  if (income <= 190000) {
    const tax = 31288 + (income - 135000) * 0.37;
    return { tax, taxSlab: 37 }; // 37% tax slab for $135,001 to $190,000
  }

  const tax = 51638 + (income - 190000) * 0.45;
  return { tax, taxSlab: 45 }; // 45% tax slab for income over $190,000
}

// Example usage with type safety
export function calculateTaxWithDetails(income: number): TaxResult {
  const { tax, taxSlab } = calculateResidentTax(income);
  const effectiveRate = (tax / income) * 100;

  return {
    income: Number(income.toFixed(2)),
    tax: Number(tax.toFixed(2)),
    effectiveRate: Number(effectiveRate.toFixed(2)),
    taxSlab: Number(taxSlab.toFixed(0))
  };
}
