import { Auth } from "aws-amplify";
import AuthenticatedAPI from "../../auth_helper";


export const GetUserProfile = async (id: any) => {
  try {
    const currentUser = await Auth?.currentAuthenticatedUser();
    if (!currentUser) {
      throw new Error('No authenticated user found');
    }

    let response
    if (id) {
      response = await AuthenticatedAPI.request(
        'InvestmentAPI',
        'get',
        `/api/profile/${id}`
      );


    } else {
      response = await AuthenticatedAPI.request(
        'InvestmentAPI',
        'get',
        `/api/profile/${currentUser.username}`
      );

    }

    if (!response) {
      throw new Error('Failed to fetch user profile');
    }

    return {
      ...response.data,
      logo_url: response.data.logo_url || null,
      theme_color: response.data.theme_color || null
    };

  } catch (error) {
    console.error('Error fetching user profile:', error);
    return null; // Or some default/fallback value
  }
};
export const CreateBuyerAgent = async (buyerData: any) => {
  try {
    const response = await AuthenticatedAPI.request(
      'BuyersAgentsAPI',
      'post',
      '/agents/buyers-agents', {
      body: {
        userId: buyerData.id || '',
        email: buyerData.email || '',
        company_name: buyerData.last_name || '',
        primary_contact_name: buyerData.contact_name || '',
      },
    }
    );

    if (!response) {
      throw new Error('Failed to create buyer-agent');
    }

    return response.data;
  } catch (error) {
    console.error('Error creating buyer-agent:', error);
    return null; // Or some default/fallback value
  }
};


export const RemoveFamilyMember = async (id: string) => {

  try {
    const userData = await Auth?.currentAuthenticatedUser().then(async (res) => {

      const response = await AuthenticatedAPI.request(
        'InvestmentAPI',
        'del',
        `/api/family/${id}`
      );

      if (!response) {
        throw new Error('Failed to delete property');
      }
      return response
    });
  } catch (error) {
    console.error('Error fetching user profile:', error);
  }
}

export function getImageByName(name: string, theme: any): string {
  // Convert the name to lowercase and trim spaces
  if (name == '') {
    return theme.palette.mode === 'dark' ? './logowhite.png' : './logo.png'
  }
  const sanitizedImageName = name.toLowerCase().replace(/\s+/g, '');

  // Construct the path to the image in the public/logo folder
  const imagePath = `/logo/${sanitizedImageName}.jpg`;
  return imagePath;
}

export const GetAssociatedBuyersAgent = async () => {
  try {
    await Auth?.currentAuthenticatedUser().then(async (res) => {

      if (!res) {
        throw new Error('No authenticated user found');
      }

      const response = await AuthenticatedAPI.request(
        'InvestmentAPI',
        'get',
        `/api/investor/associated-agent/${res.username}`
      );

      if (!response) {
        console.log('Failed to fetch associated buyer\'s agent');
      }
      console.log('respons34534534e', response)
      return response.data;
    });

  } catch (error) {
    console.log('Error fetching associated buyer\'s agent:', error);
    return null;
  }
};


